/*
 * Copyright 2020 The Backstage Authors
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
import { FeatureFlagged } from '@backstage/core-app-api';
import { identityApiRef, useApi } from '@backstage/core-plugin-api';
import {
  EntityApiDefinitionCard,
  EntityConsumedApisCard,
  EntityConsumingComponentsCard,
  EntityHasApisCard,
  EntityProvidedApisCard,
  EntityProvidingComponentsCard,
} from '@backstage/plugin-api-docs';
import {
  EntityAboutCard,
  EntityDependsOnComponentsCard,
  EntityDependsOnResourcesCard,
  EntityHasComponentsCard,
  EntityHasResourcesCard,
  EntityHasSystemsCard,
  EntityLayout,
  EntityLinksCard,
  EntityOrphanWarning,
  EntityProcessingErrorsPanel,
  EntitySwitch,
  hasCatalogProcessingErrors,
  isComponentType,
  isKind,
  isOrphan,
} from '@backstage/plugin-catalog';
import { EntityCatalogGraphCard } from '@backstage/plugin-catalog-graph';
import { useEntity } from '@backstage/plugin-catalog-react';
import {
  EntityMembersListCard,
  EntityOwnershipCard,
  EntityUserProfileCard,
} from '@backstage/plugin-org';
import { EntityTechdocsContent } from '@backstage/plugin-techdocs';
import { TechDocsAddons } from '@backstage/plugin-techdocs-react';
import {
  EntityGitlabContent,
  EntityGitlabContributorsCard,
  EntityGitlabLanguageCard,
  EntityGitlabMergeRequestStatsCard,
  EntityGitlabMergeRequestsTable,
  EntityGitlabPipelinesTable,
  isGitlabAvailable,
} from '@internal/backstage-plugin-gitlab';
import { ExcellenceComponent } from '@internal/plugin-qm-ox';
import { EntityTechInsightsScorecardContent } from '@internal/plugin-qm-tech-insights';
import { TestMetricOverview } from '@internal/plugin-qm-test-metrics';
import { ReportIssue } from '@internal/plugin-techdocs-module-addons-contrib';
import { Mermaid } from '@internal/techdocs-addon-mermaid';
import { Box, Grid } from '@material-ui/core';
import React from 'react';
import useAsync from 'react-use/lib/useAsync';

import { GroupProfileCard } from './GroupProfileCard';

import {
  EntityArgoCDOverviewCard,
  isArgocdAvailable,
} from '@roadiehq/backstage-plugin-argo-cd';

// This is the groups entity reference after the group:default/ part of the string
const teamsAllowedToUnregister = [
  'delivery',
  'developer-experience',
  'site-reliability-engineering',
];

const entityWarningContent = (
  <>
    <EntitySwitch>
      <EntitySwitch.Case if={isOrphan}>
        <Grid item xs={12}>
          <EntityOrphanWarning />
        </Grid>
      </EntitySwitch.Case>
    </EntitySwitch>

    <EntitySwitch>
      <EntitySwitch.Case if={hasCatalogProcessingErrors}>
        <Grid item xs={12}>
          <EntityProcessingErrorsPanel />
        </Grid>
      </EntitySwitch.Case>
    </EntitySwitch>
  </>
);

const overviewContent = (
  <Grid container spacing={3} alignItems="stretch">
    {entityWarningContent}
    <Grid item md={6}>
      <EntityAboutCard variant="gridItem" />
    </Grid>

    <Grid item md={6} xs={12}>
      <EntitySwitch>
        <EntitySwitch.Case
          if={() => {
            const { entity } = useEntity();
            return entity?.metadata?.links?.length !== 0;
          }}
        >
          <EntityLinksCard />
        </EntitySwitch.Case>
      </EntitySwitch>
      <Box mb={3} />
      {/* <OxOverviewCard /> */}
      <Box mb={3} />
      <EntitySwitch>
        <EntitySwitch.Case if={isGitlabAvailable}>
          <EntityGitlabContributorsCard />
        </EntitySwitch.Case>
      </EntitySwitch>
      <Box mb={3} />
      <EntitySwitch>
        <EntitySwitch.Case if={e => Boolean(isArgocdAvailable(e))}>
          <Grid item>
            <EntityArgoCDOverviewCard />
          </Grid>
        </EntitySwitch.Case>
      </EntitySwitch>
    </Grid>

    <Grid item md={6}>
      <EntitySwitch>
        <EntitySwitch.Case if={entity => entity.kind !== 'Subscription'}>
          <EntityCatalogGraphCard variant="gridItem" height={400} />
        </EntitySwitch.Case>
      </EntitySwitch>
    </Grid>

    <Grid item md={6} xs={12}>
      <EntitySwitch>
        <EntitySwitch.Case if={isGitlabAvailable}>
          <EntityGitlabLanguageCard />
          <Box mb={3} />
          <EntityGitlabMergeRequestStatsCard />
        </EntitySwitch.Case>
      </EntitySwitch>
    </Grid>

    <Grid item md={6} xs={12}>
      <EntitySwitch>
        <EntitySwitch.Case if={isGitlabAvailable}>
          <EntityGitlabPipelinesTable />
        </EntitySwitch.Case>
      </EntitySwitch>
    </Grid>

    <Grid item md={6} xs={12}>
      <EntitySwitch>
        <EntitySwitch.Case if={isGitlabAvailable}>
          <EntityGitlabMergeRequestsTable />
        </EntitySwitch.Case>
      </EntitySwitch>
    </Grid>
    <FeatureFlagged with="tech-insights">
      <Grid item md={6} xs={12}>
        <EntitySwitch>
          <EntitySwitch.Case if={entity => entity.kind === 'Subscription'}>
            <EntityTechInsightsScorecardContent
              title="BQIO Score Card"
              description="Indicates wether subscription is using BQIO"
              checksId={['bqio']}
            />
          </EntitySwitch.Case>
          <EntitySwitch.Case if={entity => entity.kind !== 'Subscription'}>
            <EntityTechInsightsScorecardContent
              title="Customized title for the scorecard"
              description="Small description about scorecards"
            />
          </EntitySwitch.Case>
        </EntitySwitch>
      </Grid>
    </FeatureFlagged>
  </Grid>
);

const ServiceEntityPage = () => {
  const identityApi = useApi(identityApiRef);
  const state = useAsync(
    async () => await identityApi.getBackstageIdentity(),
    [identityApi],
  );
  const userGroups = state?.value?.ownershipEntityRefs?.map(team =>
    team.replace('group:default/', ''),
  ) ?? [''];

  const unregisterObj = {
    disableUnregister: !teamsAllowedToUnregister.some(team =>
      userGroups.includes(team),
    ),
  };

  return (
    <EntityLayout UNSTABLE_contextMenuOptions={unregisterObj}>
      <EntityLayout.Route path="/" title="Overview">
        {overviewContent}
      </EntityLayout.Route>

      <EntityLayout.Route if={isGitlabAvailable} path="/gitlab" title="Gitlab">
        <EntityGitlabContent />
      </EntityLayout.Route>

      <EntityLayout.Route path="/api" title="API">
        <Grid container spacing={3} alignItems="stretch">
          <Grid item md={6}>
            <EntityProvidedApisCard />
          </Grid>
          <Grid item md={6}>
            <EntityConsumedApisCard />
          </Grid>
        </Grid>
      </EntityLayout.Route>

      <EntityLayout.Route path="/dependencies" title="Dependencies">
        <Grid container spacing={3} alignItems="stretch">
          <Grid item md={6}>
            <EntityDependsOnComponentsCard variant="gridItem" />
          </Grid>
          <Grid item md={6}>
            <EntityDependsOnResourcesCard variant="gridItem" />
          </Grid>
        </Grid>
      </EntityLayout.Route>

      <EntityLayout.Route path="/docs" title="Docs">
        <EntityTechdocsContent>
          <TechDocsAddons>
            <Mermaid />
            <ReportIssue />
          </TechDocsAddons>
        </EntityTechdocsContent>
      </EntityLayout.Route>
      <EntityLayout.Route path="/tests" title="Tests">
        <TestMetricOverview />
      </EntityLayout.Route>
      <EntityLayout.Route path="/excellence" title="Excellence">
        <ExcellenceComponent />
      </EntityLayout.Route>
      <FeatureFlagged with="tech-insights">
        <EntityLayout.Route path="/tech-insights" title="Scorecards">
          <EntityTechInsightsScorecardContent
            title="Customized title for the scorecard"
            description="Small description about scorecards"
          />
        </EntityLayout.Route>
      </FeatureFlagged>
    </EntityLayout>
  );
};

const websiteEntityPage = (
  <EntityLayout>
    <EntityLayout.Route path="/" title="Overview">
      {overviewContent}
    </EntityLayout.Route>

    <EntityLayout.Route path="/dependencies" title="Dependencies">
      <Grid container spacing={3} alignItems="stretch">
        <Grid item md={6}>
          <EntityDependsOnComponentsCard variant="gridItem" />
        </Grid>
        <Grid item md={6}>
          <EntityDependsOnResourcesCard variant="gridItem" />
        </Grid>
      </Grid>
    </EntityLayout.Route>

    <EntityLayout.Route path="/docs" title="Docs">
      <EntityTechdocsContent>
        <TechDocsAddons>
          <Mermaid />
          <ReportIssue />
        </TechDocsAddons>
      </EntityTechdocsContent>
    </EntityLayout.Route>
  </EntityLayout>
);

/**
 * NOTE: This page is designed to work on small screens such as mobile devices.
 * This is based on Material UI Grid. If breakpoints are used, each grid item must set the `xs` prop to a column size or to `true`,
 * since this does not default. If no breakpoints are used, the items will equitably share the available space.
 * https://material-ui.com/components/grid/#basic-grid.
 */

const defaultEntityPage = (
  <EntityLayout>
    <EntityLayout.Route path="/" title="Overview">
      {overviewContent}
    </EntityLayout.Route>

    <EntityLayout.Route path="/docs" title="Docs">
      <EntityTechdocsContent>
        <TechDocsAddons>
          <Mermaid />
          <ReportIssue />
        </TechDocsAddons>
      </EntityTechdocsContent>
    </EntityLayout.Route>
  </EntityLayout>
);

const componentPage = (
  <EntitySwitch>
    <EntitySwitch.Case if={isComponentType('service')}>
      <ServiceEntityPage />
    </EntitySwitch.Case>

    <EntitySwitch.Case if={isComponentType('website')}>
      {websiteEntityPage}
    </EntitySwitch.Case>

    <EntitySwitch.Case>{defaultEntityPage}</EntitySwitch.Case>
  </EntitySwitch>
);

const apiPage = (
  <EntityLayout>
    <EntityLayout.Route path="/" title="Overview">
      <Grid container spacing={3}>
        {entityWarningContent}
        <Grid item md={6}>
          <EntityAboutCard />
        </Grid>
        <Grid item md={4} xs={12}>
          <EntityLinksCard />
        </Grid>
        <Grid container item md={12}>
          <Grid item md={6}>
            <EntityProvidingComponentsCard />
          </Grid>
          <Grid item md={6}>
            <EntityConsumingComponentsCard />
          </Grid>
        </Grid>
      </Grid>
    </EntityLayout.Route>

    <EntityLayout.Route path="/definition" title="Definition">
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <EntityApiDefinitionCard />
        </Grid>
      </Grid>
    </EntityLayout.Route>
  </EntityLayout>
);

const userPage = (
  <EntityLayout>
    <EntityLayout.Route path="/" title="Overview">
      <Grid container spacing={3}>
        {entityWarningContent}
        <Grid item xs={12} md={6}>
          <EntityUserProfileCard variant="gridItem" />
        </Grid>
        <Grid item xs={12} md={6}>
          <EntityOwnershipCard variant="gridItem" />
        </Grid>
      </Grid>
    </EntityLayout.Route>
  </EntityLayout>
);

const groupPage = (
  <EntityLayout>
    <EntityLayout.Route path="/" title="Overview">
      <Grid container spacing={3}>
        {entityWarningContent}
        <Grid item xs={12} md={6}>
          <GroupProfileCard variant="gridItem" />
        </Grid>
        <Grid item xs={12} md={6}>
          <EntityOwnershipCard variant="gridItem" />
        </Grid>
        <Grid item xs={12}>
          <EntityMembersListCard />
        </Grid>
      </Grid>
    </EntityLayout.Route>
  </EntityLayout>
);

const systemPage = (
  <EntityLayout>
    <EntityLayout.Route path="/" title="Overview">
      <Grid container spacing={3} alignItems="stretch">
        {entityWarningContent}
        <Grid item md={6}>
          <EntityAboutCard variant="gridItem" />
        </Grid>
        <Grid item md={6}>
          <EntityHasComponentsCard variant="gridItem" />
        </Grid>
        <Grid item md={6}>
          <EntityHasApisCard variant="gridItem" />
        </Grid>
        <Grid item md={6}>
          <EntityHasResourcesCard variant="gridItem" />
        </Grid>
      </Grid>
    </EntityLayout.Route>
  </EntityLayout>
);

const domainPage = (
  <EntityLayout>
    <EntityLayout.Route path="/" title="Overview">
      <Grid container spacing={3} alignItems="stretch">
        {entityWarningContent}
        <Grid item md={6}>
          <EntityAboutCard variant="gridItem" />
        </Grid>
        <Grid item md={6}>
          <EntityHasSystemsCard variant="gridItem" />
        </Grid>
      </Grid>
    </EntityLayout.Route>
  </EntityLayout>
);

export const entityPage = (
  <EntitySwitch>
    <EntitySwitch.Case if={isKind('component')} children={componentPage} />
    <EntitySwitch.Case if={isKind('api')} children={apiPage} />
    <EntitySwitch.Case if={isKind('group')} children={groupPage} />
    <EntitySwitch.Case if={isKind('user')} children={userPage} />
    <EntitySwitch.Case if={isKind('system')} children={systemPage} />
    <EntitySwitch.Case if={isKind('domain')} children={domainPage} />

    <EntitySwitch.Case>{defaultEntityPage}</EntitySwitch.Case>
  </EntitySwitch>
);
